import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/seo/MetaData';
import OrganizationStructuredData from '../../components/seo/OrganizationStructuredData';
import Container from '../../components/layout/Container';
import SmallContainer from '../../components/layout/SmallContainer';
import Image from "../../images/vegetar-vs-veganer-vs-pescetar-vs-fleksitar.png";
import Table from '../../components/table/Table';

interface Props extends PageProps {
  location: any;
}

const VegetarVsVeganer: React.FunctionComponent<Props> = ({ location }) => {

  const { site } = useStaticQuery<any>(
    graphql`
      query vegetarQuery{
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  
  return (
    <Layout>
      <MetaData 
        title="Vegetar vs. Veganer vs. Pescetar vs. Fleksitar" 
        thumbnailUrl={site.siteMetadata.siteUrl + Image}
        description="Hvad er forskellen på en vegetar vs. veganer vs. pescetar vs. fleksitar? Få svarene her og lær mere omkring de forskellige former for diæter."
        pageUrl={location.pathname}
        imageAlt="Vegetar vs. Veganer vs. Pescetar vs. Fleksitar"
      />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Vegetar vs. Veganer vs. Pescetar vs. Fleksitar</h1>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <SmallContainer className="pt-4 mb-16">
            <img 
              src={Image} 
              alt="vegetar vs. veganer vs. pescetar vs. fleksitar"
              className="rounded-md mt-4 mb-4"
            />
            <p className="text-base">
              Der er efterhånden mange forskellige betegnelser til at beskrive diæter. 
              Her beskriver vi forskellen på vegetar vs. veganer vs. pescetar vs. fleksitar. Mangel på information på området kan nemlig skabe forvirring hos nogle, da ikke alle kender meget til området.
              I skemaet herunder kan du se et hurtigt overblik over de forskellige diæter.
            </p>
            <Table />
            <h3>Vegetar</h3>
            <p className="text-base">
                Vegetarisk er en kost uden kød. Dette betyder, at man som vegetar hverken spiser fjerkræ, kød, fisk eller skaldyr.
                Dette er udgangspunktet for alle vegetarer. Der er dog stadig nogle, som har andre specifikke præferencer.
                Eksempelvis vælger nogle vegetarer stadig ikke at spise nogle mejeriprodukter.
            </p>
            <p className="text-base">
                De vigtigste kilder af næring for en vegetar er grøntsager, korn og frugt.
                Der er nogle fødevarer som bliver mere centrale, da vegetarer har brug for en alternativ kilde til protein.
                Specielt bønner og linser bliver tit brugt, da de er gode kilder til protein.
            </p>
            <h3>Veganer</h3>
            <p className="text-base">
                Veganisme er baseret på at fravælge brugen af animalske produkter. Specifikt i diæten vil en veganer derfor undgå animalske produkter.
                Dette betyder, at en veganer ikke spiser kød, fjerkræ, fisk, skaldyr, mejeriprodukter og æg. 
                Mange veganere betragter dog ikke kun veganisme som en diæt.
                For mange er det en filosofi, der strækker sig ud i andre dele af livet.
                Generelt vælger en større del af veganere at tage afstand fra brugen af dyr til produkter.
            </p>
            <h3>Pescetar</h3>
            <p className="text-base">
                Ordet pescetar stammer fra det italienske ord for fisk 'pesce' og ordet 'vegetar' eller 'vegetarian'.
                Sætter man dem sammen får man også kosten for en pescetar. Det er en person, som lever udpræget vegetarisk, men stadig spiser frisk og skaldyr.
                En pescetar spiser derfor ikke kød og fjerkræ.
            </p>
            <h3>Fleksitar</h3>
            <p className="text-base">
                Dette er en af de mindre kendte diæter.
                En fleksitar bliver også kaldt en semi-vegetar eller lejlighedsvis vegetar.
                Det betyder, at man generelt spiser vegetarisk, men spiser kød ved lejligheder.
                Grunden til diæten kan være mange forskellige.
                Nogle af de mere populære grunde er
            </p>
            <h3>Andre diæter</h3>
            <p className="text-base">
                De nævnte diæter ovenover er de mest udbredte i Danmark. 
                Der findes dog en lang række udstikkere, som flere folk begynder at identificere sig med.
            </p>
            <h6>Lakto-ovo vegetar</h6>
            <p className="text-base">
                Dette er vegetarer, som stadig spiser æg og mælkeprodukter. 
                Så det er egentlig i praksis blot dette, som mange forstår ved en vegetar.
            </p>
            <h6>Lakto vegetar</h6>
            <p className="text-base">
                Her spises der stadig mælkebaserede produkter. Der spises blot ikke æg.
                Så denne diæt er ligeledes meget tæt på den almindelige vegetariske diæt.
            </p>
        </SmallContainer>
      </div>
    </Layout>
  );
};

export default VegetarVsVeganer;