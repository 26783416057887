import React from 'react';
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import Colors from '../../constants/Colors';

const Table: React.FunctionComponent<{}> = () => (
  <table className="table-auto w-full mt-12">
    <thead>
      <tr>
        <th className="w-1/2 px-4 py-2">Kost</th>
        <th className="w-1/4 px-4 py-2">Kød</th>
        <th className="w-1/4 px-4 py-2">Fisk</th>
        <th className="w-1/4 px-4 py-2">Mejeriprodukter</th>
      </tr>
    </thead>
    <tbody>
      <tr className="bg-white">
        <td className="border px-4 py-2">Vegetar</td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
      </tr>
      <tr className="bg-white">
        <td className="border px-4 py-2">Veganer</td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
      </tr>
      <tr className="bg-white">
        <td className="border px-4 py-2">Pescetar</td>
        <td className="border px-4 py-2">
          <FaRegTimesCircle
            size={25}
            color={Colors.red}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
      </tr>
      <tr className="bg-white">
        <td className="border px-4 py-2">Fleksitar</td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
        <td className="border px-4 py-2">
          <FaCheckCircle
            size={25}
            color={Colors.green}
            className="m-0 m-auto"
          />
        </td>
      </tr>
    </tbody>
  </table>
);

export default Table;
